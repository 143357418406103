export enum Wallet {
  METAMASK = "METAMASK",
  "UNIPASS" = "UNIPASS",
  "JOYID" = "JOYID",
  "WALLETCONNECT" = "WALLETCONNECT",
}

export enum WalletType {
  EOA = "EOA",
  AA = "AA",
}
